<template>
  <v-container fluid class="pa-0 ">
    <v-card
      outlined
      min-height="100vh"
      color="#27F0CE"
      class="pa-0"
      elevation="0"
    >
      <v-container class="pa-0" style="max-width: 1200px">
        <v-row align="center" class="mx-0">
          <v-col
          
            cols="12"
            sm="6"
            class="pa-0 "
            order="2"
            order-sm="1"
          >
            <slot name="grid-left"></slot>
          </v-col>
          <v-col cols="12"  sm="6" class="pa-0  pa-md-4" order="1">
            <slot name="grid-right"></slot>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
};
</script>

<style scoped>
</style>